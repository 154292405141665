import { useCallback } from 'react';

import { Add } from '@carbon/icons-react';
import { Button, Loading, Modal } from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';
import { mediaQueries } from '@wastewizer/ui-constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from 'usehooks-ts';

import { useUser } from '#hooks/useUser';
import { useCreateContainerSiteMutation } from './_generated';
import { ContainerSiteForm, ContainerSiteFormData } from '../ContainerSiteForm';

export type CreateContainerSiteProps = {
  afterCreate: () => void;
};

export const CreateContainerSite: React.FunctionComponent<
  CreateContainerSiteProps
> = ({ afterCreate }) => {
  const user = useUser();
  const { preferences: { weightUnit } = {} } = user;
  const isSm = useMediaQuery(mediaQueries.sm);
  const formMethods = useForm<ContainerSiteFormData>({
    mode: 'all',
  });

  const [createContainerSite, { loading: creating }] =
    useCreateContainerSiteMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => (
      <Button
        hasIconOnly={isSm}
        renderIcon={Add}
        iconDescription="New container site"
        tooltipAlignment="end"
        onClick={() => {
          setOpen(true);
        }}
      >
        New container site
      </Button>
    ),
    [isSm],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <Modal
        open={open}
        preventCloseOnClickOutside
        modalHeading="Add a new container site"
        primaryButtonText="Save"
        selectorPrimaryFocus="#serviceLocationId"
        onRequestClose={() => {
          setOpen(false);
        }}
        onRequestSubmit={formMethods.handleSubmit(
          async (data: ContainerSiteFormData) => {
            const { maxNetWeight: _maxNetWeight, ...rest } = data;
            await createContainerSite({
              variables: {
                input: {
                  ...rest,
                  emptyContainerWeight: {
                    unit: weightUnit,
                    value: data.emptyContainerWeight,
                  },
                  maxGrossWeight: {
                    unit: weightUnit,
                    value: data.maxGrossWeight,
                  },
                },
              },
            });

            formMethods.reset();

            setOpen(false);
            afterCreate();
          },
        )}
      >
        <>
          <Loading withOverlay active={creating} />
          <FormProvider {...formMethods}>
            <ContainerSiteForm />
          </FormProvider>
        </>
      </Modal>
    ),
    [formMethods, creating, createContainerSite, afterCreate, weightUnit],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey="create-container-site-modal"
    />
  );
};
