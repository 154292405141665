import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UploadInstallPhotosDocument = gql`
  mutation UploadInstallPhotos($id: String!, $fileNames: [String!]!) {
    uploadContainerSiteInstallPhotos(id: $id, fileNames: $fileNames) {
      id
      uploadUri
    }
  }
`;
export type UploadInstallPhotosMutationFn = Apollo.MutationFunction<
  UploadInstallPhotosMutation,
  UploadInstallPhotosMutationVariables
>;

/**
 * __useUploadInstallPhotosMutation__
 *
 * To run a mutation, you first call `useUploadInstallPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInstallPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInstallPhotosMutation, { data, loading, error }] = useUploadInstallPhotosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileNames: // value for 'fileNames'
 *   },
 * });
 */
export function useUploadInstallPhotosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadInstallPhotosMutation,
    UploadInstallPhotosMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadInstallPhotosMutation,
    UploadInstallPhotosMutationVariables
  >(UploadInstallPhotosDocument, options);
}
export type UploadInstallPhotosMutationHookResult = ReturnType<
  typeof useUploadInstallPhotosMutation
>;
export type UploadInstallPhotosMutationResult =
  Apollo.MutationResult<UploadInstallPhotosMutation>;
export type UploadInstallPhotosMutationOptions = Apollo.BaseMutationOptions<
  UploadInstallPhotosMutation,
  UploadInstallPhotosMutationVariables
>;
export const UploadRemovalPhotosDocument = gql`
  mutation UploadRemovalPhotos($id: String!, $fileNames: [String!]!) {
    uploadContainerCycleRemovalPhotos(id: $id, fileNames: $fileNames) {
      id
      uploadUri
    }
  }
`;
export type UploadRemovalPhotosMutationFn = Apollo.MutationFunction<
  UploadRemovalPhotosMutation,
  UploadRemovalPhotosMutationVariables
>;

/**
 * __useUploadRemovalPhotosMutation__
 *
 * To run a mutation, you first call `useUploadRemovalPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRemovalPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRemovalPhotosMutation, { data, loading, error }] = useUploadRemovalPhotosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileNames: // value for 'fileNames'
 *   },
 * });
 */
export function useUploadRemovalPhotosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadRemovalPhotosMutation,
    UploadRemovalPhotosMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadRemovalPhotosMutation,
    UploadRemovalPhotosMutationVariables
  >(UploadRemovalPhotosDocument, options);
}
export type UploadRemovalPhotosMutationHookResult = ReturnType<
  typeof useUploadRemovalPhotosMutation
>;
export type UploadRemovalPhotosMutationResult =
  Apollo.MutationResult<UploadRemovalPhotosMutation>;
export type UploadRemovalPhotosMutationOptions = Apollo.BaseMutationOptions<
  UploadRemovalPhotosMutation,
  UploadRemovalPhotosMutationVariables
>;
export const GetInstallPhotosDocument = gql`
  query GetInstallPhotos($id: ID!) {
    containerSite: containerSiteById(id: $id) {
      id
      installPhotos {
        id
        status
      }
    }
  }
`;

/**
 * __useGetInstallPhotosQuery__
 *
 * To run a query within a React component, call `useGetInstallPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallPhotosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstallPhotosQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstallPhotosQuery,
    GetInstallPhotosQueryVariables
  > &
    (
      | { variables: GetInstallPhotosQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInstallPhotosQuery, GetInstallPhotosQueryVariables>(
    GetInstallPhotosDocument,
    options,
  );
}
export function useGetInstallPhotosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstallPhotosQuery,
    GetInstallPhotosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInstallPhotosQuery,
    GetInstallPhotosQueryVariables
  >(GetInstallPhotosDocument, options);
}
export function useGetInstallPhotosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetInstallPhotosQuery,
        GetInstallPhotosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInstallPhotosQuery,
    GetInstallPhotosQueryVariables
  >(GetInstallPhotosDocument, options);
}
export type GetInstallPhotosQueryHookResult = ReturnType<
  typeof useGetInstallPhotosQuery
>;
export type GetInstallPhotosLazyQueryHookResult = ReturnType<
  typeof useGetInstallPhotosLazyQuery
>;
export type GetInstallPhotosSuspenseQueryHookResult = ReturnType<
  typeof useGetInstallPhotosSuspenseQuery
>;
export type GetInstallPhotosQueryResult = Apollo.QueryResult<
  GetInstallPhotosQuery,
  GetInstallPhotosQueryVariables
>;
export const GetRemovalPhotosDocument = gql`
  query GetRemovalPhotos($id: ID!) {
    containerSite: containerCycleById(id: $id) {
      id
      removalPhotos {
        id
        status
      }
    }
  }
`;

/**
 * __useGetRemovalPhotosQuery__
 *
 * To run a query within a React component, call `useGetRemovalPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemovalPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemovalPhotosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRemovalPhotosQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRemovalPhotosQuery,
    GetRemovalPhotosQueryVariables
  > &
    (
      | { variables: GetRemovalPhotosQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRemovalPhotosQuery, GetRemovalPhotosQueryVariables>(
    GetRemovalPhotosDocument,
    options,
  );
}
export function useGetRemovalPhotosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemovalPhotosQuery,
    GetRemovalPhotosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRemovalPhotosQuery,
    GetRemovalPhotosQueryVariables
  >(GetRemovalPhotosDocument, options);
}
export function useGetRemovalPhotosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRemovalPhotosQuery,
        GetRemovalPhotosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRemovalPhotosQuery,
    GetRemovalPhotosQueryVariables
  >(GetRemovalPhotosDocument, options);
}
export type GetRemovalPhotosQueryHookResult = ReturnType<
  typeof useGetRemovalPhotosQuery
>;
export type GetRemovalPhotosLazyQueryHookResult = ReturnType<
  typeof useGetRemovalPhotosLazyQuery
>;
export type GetRemovalPhotosSuspenseQueryHookResult = ReturnType<
  typeof useGetRemovalPhotosSuspenseQuery
>;
export type GetRemovalPhotosQueryResult = Apollo.QueryResult<
  GetRemovalPhotosQuery,
  GetRemovalPhotosQueryVariables
>;
export type UploadInstallPhotosMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  fileNames:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
}>;

export type UploadInstallPhotosMutation = {
  __typename?: 'Mutation';
  uploadContainerSiteInstallPhotos: Array<{
    __typename?: 'CloudStorageFileUpload';
    id: string;
    uploadUri: string;
  }>;
};

export type UploadRemovalPhotosMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  fileNames:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
}>;

export type UploadRemovalPhotosMutation = {
  __typename?: 'Mutation';
  uploadContainerCycleRemovalPhotos: Array<{
    __typename?: 'CloudStorageFileUpload';
    id: string;
    uploadUri: string;
  }>;
};

export type GetInstallPhotosQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetInstallPhotosQuery = {
  __typename?: 'Query';
  containerSite: {
    __typename?: 'ContainerSite';
    id: string;
    installPhotos: Array<{
      __typename?: 'CloudStorageFile';
      id: string;
      status: Types.UploadStatus;
    }>;
  };
};

export type GetRemovalPhotosQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetRemovalPhotosQuery = {
  __typename?: 'Query';
  containerSite?: {
    __typename?: 'ContainerCycle';
    id: string;
    removalPhotos: Array<{
      __typename?: 'CloudStorageFile';
      id: string;
      status: Types.UploadStatus;
    }>;
  } | null;
};
