import React from 'react';

import { FullnessBar } from '@wastewizer/ui-components';

import { useUser } from '#hooks/useUser';
import { ContainerSiteTableRowFragment } from '../_generated';

export type FullnessProps = {
  containerSite: ContainerSiteTableRowFragment;
  showHelper?: boolean;
};

export const Fullness: React.FunctionComponent<FullnessProps> = ({
  containerSite,
  showHelper = true,
}) => {
  const user = useUser();
  const {
    preferences: { weightLabel, weightDisplay },
  } = user;

  return (
    <FullnessBar
      maxWeight={containerSite.maxDisplayWeight}
      weight={containerSite.currentDisplayWeight}
      weightLabel={weightLabel.pluralAbbrev}
      weightDisplay={weightDisplay.toLowerCase()}
      showHelper={showHelper}
    />
  );
};
