import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import {
  UserSelectItemFragmentDoc,
  ServiceLocationSelectItemWithAccountFragmentDoc,
} from '../../../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AssignDriverDocument = gql`
  mutation AssignDriver($id: ID!, $driverId: ID) {
    updateContainerSiteDriver(id: $id, driverId: $driverId) {
      id
      assignedDriver {
        id
        name
      }
    }
  }
`;
export type AssignDriverMutationFn = Apollo.MutationFunction<
  AssignDriverMutation,
  AssignDriverMutationVariables
>;

/**
 * __useAssignDriverMutation__
 *
 * To run a mutation, you first call `useAssignDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDriverMutation, { data, loading, error }] = useAssignDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useAssignDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignDriverMutation,
    AssignDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignDriverMutation,
    AssignDriverMutationVariables
  >(AssignDriverDocument, options);
}
export type AssignDriverMutationHookResult = ReturnType<
  typeof useAssignDriverMutation
>;
export type AssignDriverMutationResult =
  Apollo.MutationResult<AssignDriverMutation>;
export type AssignDriverMutationOptions = Apollo.BaseMutationOptions<
  AssignDriverMutation,
  AssignDriverMutationVariables
>;
export const GetDriversForAccountDocument = gql`
  query GetDriversForAccount($accountId: String!) {
    drivers: searchUsers(
      filter: [
        { filterBy: ACCOUNT_ID, value: $accountId }
        { filterBy: ACCOUNT_ROLE, value: "DRIVER" }
      ]
      page: { page: 0, pageSize: 0 }
    ) {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;

/**
 * __useGetDriversForAccountQuery__
 *
 * To run a query within a React component, call `useGetDriversForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetDriversForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  > &
    (
      | { variables: GetDriversForAccountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export function useGetDriversForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export function useGetDriversForAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDriversForAccountQuery,
        GetDriversForAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export type GetDriversForAccountQueryHookResult = ReturnType<
  typeof useGetDriversForAccountQuery
>;
export type GetDriversForAccountLazyQueryHookResult = ReturnType<
  typeof useGetDriversForAccountLazyQuery
>;
export type GetDriversForAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetDriversForAccountSuspenseQuery
>;
export type GetDriversForAccountQueryResult = Apollo.QueryResult<
  GetDriversForAccountQuery,
  GetDriversForAccountQueryVariables
>;
export type AssignDriverMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  driverId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type AssignDriverMutation = {
  __typename?: 'Mutation';
  updateContainerSiteDriver: {
    __typename?: 'ContainerSite';
    id: string;
    assignedDriver?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null;
  };
};

export type GetDriversForAccountQueryVariables = Types.Exact<{
  accountId: Types.Scalars['String']['input'];
}>;

export type GetDriversForAccountQuery = {
  __typename?: 'Query';
  drivers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
};
