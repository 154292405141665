import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateContainerSiteDocument = gql`
  mutation CreateContainerSite($input: CreateContainerSiteInput!) {
    createContainerSite(input: $input) {
      id
    }
  }
`;
export type CreateContainerSiteMutationFn = Apollo.MutationFunction<
  CreateContainerSiteMutation,
  CreateContainerSiteMutationVariables
>;

/**
 * __useCreateContainerSiteMutation__
 *
 * To run a mutation, you first call `useCreateContainerSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerSiteMutation, { data, loading, error }] = useCreateContainerSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContainerSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContainerSiteMutation,
    CreateContainerSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContainerSiteMutation,
    CreateContainerSiteMutationVariables
  >(CreateContainerSiteDocument, options);
}
export type CreateContainerSiteMutationHookResult = ReturnType<
  typeof useCreateContainerSiteMutation
>;
export type CreateContainerSiteMutationResult =
  Apollo.MutationResult<CreateContainerSiteMutation>;
export type CreateContainerSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateContainerSiteMutation,
  CreateContainerSiteMutationVariables
>;
export type CreateContainerSiteMutationVariables = Types.Exact<{
  input: Types.CreateContainerSiteInput;
}>;

export type CreateContainerSiteMutation = {
  __typename?: 'Mutation';
  createContainerSite: { __typename?: 'ContainerSite'; id: string };
};
