'use client';

import React from 'react';

import { PageHeader } from '@wastewizer/ui-components';
import { NextPage } from 'next';

import { ContainerSitesTable } from './_components/ContainerSitesTable';

const Page: NextPage = () => {
  return (
    <>
      <PageHeader title="Container sites" />
      <ContainerSitesTable />
    </>
  );
};

export default Page;
