import { useState } from 'react';

import { Edit } from '@carbon/icons-react';
import {
  Button,
  ButtonSet,
  ComboBox,
  IconButton,
  Popover,
  PopoverContent,
  Stack,
} from '@carbon/react';

import { UserSelectItemFragment } from '#fragments';
import {
  useAssignDriverMutation,
  useGetDriversForAccountLazyQuery,
} from './_generated';
import styles from './AssignDriver.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';

export type AssignDriverProps = {
  containerSite: ContainerSiteTableRowFragment;
  align?: 'right' | 'top-left';
};

export const AssignDriver: React.FunctionComponent<AssignDriverProps> = ({
  containerSite,
  align = 'right',
}) => {
  const [open, setOpen] = useState(false);
  const [accountDrivers, setAccountDrivers] = useState<
    UserSelectItemFragment[]
  >([]);
  const [assignedDriverId, setAssignedDriverId] = useState<
    string | undefined
  >();

  const [fetchDrivers] = useGetDriversForAccountLazyQuery();
  const [assignDriver] = useAssignDriverMutation();

  return (
    <Popover open={open} align={align}>
      <IconButton
        size="sm"
        kind="ghost"
        label="Assign driver"
        onClick={async () => {
          const accountId = containerSite.serviceLocation?.account.id;
          const { data } = await fetchDrivers({
            variables: {
              accountId,
            },
          });
          setAccountDrivers(data.drivers);
          setOpen(true);
        }}
      >
        <Edit />
      </IconButton>
      <PopoverContent>
        <div className={styles.editDriverPopup}>
          <Stack gap={2}>
            <ComboBox
              id={`driver-edit-${containerSite.id}`}
              placeholder="Select driver"
              items={accountDrivers}
              itemToString={(item) => item?.name ?? ''}
              selectedItem={accountDrivers.find(
                (d) => d.id === containerSite.assignedDriver?.id,
              )}
              onChange={({ selectedItem }) => {
                setAssignedDriverId(selectedItem?.id);
              }}
              readOnly={!accountDrivers.length}
              warn={!accountDrivers.length}
              warnText="There are no drivers setup for this account"
            />
            <ButtonSet className={styles.buttonSet}>
              <Button size="sm" kind="secondary" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                size="sm"
                kind="primary"
                onClick={async () => {
                  setOpen(false);
                  await assignDriver({
                    variables: {
                      id: containerSite.id,
                      driverId: assignedDriverId,
                    },
                  });
                }}
              >
                Save
              </Button>
            </ButtonSet>
          </Stack>
        </div>
      </PopoverContent>
    </Popover>
  );
};
