import { Information } from '@carbon/icons-react';
import {
  Checkbox,
  Form,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react';
import { useFormContext } from 'react-hook-form';

import { AlertType } from '#generated-types';
import { useGetCurrentUserQuery } from './_generated';
import { alerts } from './alerts';

type AlertSubscriptionItem = {
  alertType: AlertType;
  sendEmail: boolean;
  sendSms: boolean;
};

export type AlertSubscriptionsFormData = {
  alertSubscriptions: AlertSubscriptionItem[];
};

export const AlertSubscriptionsForm: React.FunctionComponent = () => {
  const { setValue, getValues, watch } = useFormContext();

  const { data } = useGetCurrentUserQuery();

  const alertSubscriptions = watch('alertSubscriptions');

  const handleChange = (
    checked: boolean,
    notificationKind: 'email' | 'sms',
    alertType: AlertType,
  ) => {
    const allSubscriptions: AlertSubscriptionItem[] =
      getValues('alertSubscriptions') || [];
    let subscription = allSubscriptions.find((s) => s.alertType === alertType);

    if (!subscription) {
      subscription = {
        alertType,
        sendEmail: checked && notificationKind === 'email',
        sendSms: checked && notificationKind === 'sms',
      };
      allSubscriptions.push(subscription);
    } else {
      if (notificationKind === 'email') {
        subscription.sendEmail = checked;
      }
      if (notificationKind === 'sms') {
        subscription.sendSms = checked;
      }
    }

    setValue('alertSubscriptions', allSubscriptions);
  };

  return (
    <Form>
      <StructuredListWrapper>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Alert</StructuredListCell>
            <StructuredListCell head>Email</StructuredListCell>
            <StructuredListCell head>
              <div className="ww--toggle-tip">
                <span>SMS</span>{' '}
                <Toggletip align="bottom">
                  <ToggletipButton label="Show information">
                    <Information />
                  </ToggletipButton>
                  <ToggletipContent>
                    {data?.user.phone?.isVerified ? (
                      <p>
                        Notifications will be sent to{' '}
                        <span className="ww--no-wrap">
                          {data.user.phone.formattedNumber}
                        </span>
                      </p>
                    ) : (
                      <p>
                        Please add and verify a US mobile phone number in
                        settings before setting up SMS notifications
                      </p>
                    )}
                  </ToggletipContent>
                </Toggletip>
              </div>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          {Object.entries(alerts).map(([alertType, info]) => {
            const subscription = alertSubscriptions?.find(
              (s) => s.alertType === alertType,
            );
            return (
              <StructuredListRow key={alertType}>
                <StructuredListCell>
                  <div className="ww--toggle-tip">
                    <span>{info.name}</span>{' '}
                    <Toggletip align="right">
                      <ToggletipButton label="Show information">
                        <Information />
                      </ToggletipButton>
                      <ToggletipContent>
                        <p>{info.description}</p>
                      </ToggletipContent>
                    </Toggletip>
                  </div>
                </StructuredListCell>
                <StructuredListCell>
                  <Checkbox
                    id={`checkbox-email-${alertType}`}
                    hideLabel
                    labelText={info.name}
                    checked={subscription?.sendEmail || false}
                    onChange={(_, { checked }) => {
                      handleChange(checked, 'email', alertType as AlertType);
                    }}
                  />
                </StructuredListCell>
                <StructuredListCell>
                  <Checkbox
                    id={`checkbox-sms-${alertType}`}
                    hideLabel
                    labelText={info.name}
                    checked={subscription?.sendSms || false}
                    onChange={(_, { checked }) => {
                      handleChange(checked, 'sms', alertType as AlertType);
                    }}
                    disabled={!data?.user.phone?.isVerified}
                  />
                </StructuredListCell>
              </StructuredListRow>
            );
          })}
        </StructuredListBody>
      </StructuredListWrapper>
    </Form>
  );
};
