import * as Types from '../_generated-types/index';

import { gql } from '@apollo/client';
export const AccountSelectItemFragmentDoc = gql`
  fragment AccountSelectItem on Account {
    id
    name
  }
`;
export const BinBarSelectItemFragmentDoc = gql`
  fragment BinBarSelectItem on BinBar {
    id
    name
  }
`;
export const ContainerSiteSelectItemFragmentDoc = gql`
  fragment ContainerSiteSelectItem on ContainerSite {
    id
    name
  }
`;
export const ServiceLocationSelectItemFragmentDoc = gql`
  fragment ServiceLocationSelectItem on ServiceLocation {
    id
    name
  }
`;
export const ServiceLocationSelectItemWithAccountFragmentDoc = gql`
  fragment ServiceLocationSelectItemWithAccount on ServiceLocation {
    id
    name
    account {
      id
    }
  }
`;
export const UserSelectItemFragmentDoc = gql`
  fragment UserSelectItem on User {
    id
    name
    email
  }
`;
export const UserPhoneItemFragmentDoc = gql`
  fragment UserPhoneItem on UserPhone {
    number
    formattedNumber @client
    isVerified
  }
`;
export type AccountSelectItemFragment = {
  __typename?: 'Account';
  id: string;
  name: string;
};

export type BinBarSelectItemFragment = {
  __typename?: 'BinBar';
  id: string;
  name: string;
};

export type ContainerSiteSelectItemFragment = {
  __typename?: 'ContainerSite';
  id: string;
  name: string;
};

export type ServiceLocationSelectItemFragment = {
  __typename?: 'ServiceLocation';
  id: string;
  name: string;
};

export type ServiceLocationSelectItemWithAccountFragment = {
  __typename?: 'ServiceLocation';
  id: string;
  name: string;
  account: { __typename?: 'Account'; id: string };
};

export type UserSelectItemFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  email: string;
};

export type UserPhoneItemFragment = {
  __typename?: 'UserPhone';
  number: string;
  formattedNumber?: string | null;
  isVerified: boolean;
};
