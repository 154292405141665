import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DeleteContainerSiteDocument = gql`
  mutation DeleteContainerSite($id: ID!) {
    deleteContainerSite(id: $id)
  }
`;
export type DeleteContainerSiteMutationFn = Apollo.MutationFunction<
  DeleteContainerSiteMutation,
  DeleteContainerSiteMutationVariables
>;

/**
 * __useDeleteContainerSiteMutation__
 *
 * To run a mutation, you first call `useDeleteContainerSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContainerSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContainerSiteMutation, { data, loading, error }] = useDeleteContainerSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContainerSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContainerSiteMutation,
    DeleteContainerSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteContainerSiteMutation,
    DeleteContainerSiteMutationVariables
  >(DeleteContainerSiteDocument, options);
}
export type DeleteContainerSiteMutationHookResult = ReturnType<
  typeof useDeleteContainerSiteMutation
>;
export type DeleteContainerSiteMutationResult =
  Apollo.MutationResult<DeleteContainerSiteMutation>;
export type DeleteContainerSiteMutationOptions = Apollo.BaseMutationOptions<
  DeleteContainerSiteMutation,
  DeleteContainerSiteMutationVariables
>;
export type DeleteContainerSiteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteContainerSiteMutation = {
  __typename?: 'Mutation';
  deleteContainerSite?: boolean | null;
};
