import { GroupObjects } from '@carbon/icons-react';
import { IconButton } from '@carbon/react';
import { LocalStorageKey } from '@wastewizer/ui-constants';
import { useLocalStorage } from 'usehooks-ts';

export const GroupResultsToggle: React.FunctionComponent = () => {
  const [groupResults, setGroupResults] = useLocalStorage<boolean>(
    LocalStorageKey.GROUP_CONTAINER_SITES,
    false,
  );

  return (
    <IconButton
      label={groupResults ? 'Ungroup results' : 'Group by service location'}
      align="top-right"
      kind="ghost"
      onClick={() => {
        setGroupResults(!groupResults);
      }}
    >
      <GroupObjects className={groupResults ? 'ww--fill-orange' : undefined} />
    </IconButton>
  );
};
