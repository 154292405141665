import { Checkbox, ComboBox, Form, Stack } from '@carbon/react';
import { useFormContext } from 'react-hook-form';

import {
  AccountSelectItemFragment,
  ServiceLocationSelectItemFragment,
  UserSelectItemFragment,
} from '#fragments';
import {
  useGetAllAccountsQuery,
  useGetAllDriversQuery,
  useGetServiceLocationsForAccountLazyQuery,
} from './_generated';

export type ContainerSitesFilterFormData = {
  accountId?: string;
  serviceLocationId?: string;
  hasBinBar?: boolean;
  driverId?: string;
};

export const ContainerSitesFilterForm: React.FunctionComponent = () => {
  const { register, setValue, watch } = useFormContext();

  // ComboBox cannot be fully controlled with react-hook-form
  register('accountId');
  const accountId = watch('accountId');

  register('serviceLocationId');
  const serviceLocationId = watch('serviceLocationId');

  register('driverId');
  const driverId = watch('driverId');

  const [getServiceLocations, { data: { account } = { account: undefined } }] =
    useGetServiceLocationsForAccountLazyQuery();

  const { data: { accounts } = { accounts: [] } } = useGetAllAccountsQuery({
    onCompleted: (data) => {
      if (data.accounts.length > 0 && accountId) {
        getServiceLocations({
          variables: { id: accountId },
        });
      }
    },
  });

  const { data: { drivers } = { drivers: [] } } = useGetAllDriversQuery();

  return (
    <Form>
      <Stack gap={6}>
        <Checkbox
          id="hasBinBar"
          labelText="Only show container sites with a BinBar"
          {...register('hasBinBar')}
        />
        <ComboBox
          id="accountId"
          data-1p-ignore
          titleText="Account"
          placeholder="Select account"
          items={accounts}
          itemToString={(item?: AccountSelectItemFragment) => item?.name ?? ''}
          selectedItem={accounts.find((a) => a.id === accountId)}
          onChange={(e) => {
            setValue('accountId', e.selectedItem?.id);

            if (e.selectedItem?.id) {
              getServiceLocations({
                variables: { id: e.selectedItem.id },
              });
            }
          }}
        />
        <ComboBox
          id="serviceLocationId"
          data-1p-ignore
          titleText="Service location"
          placeholder="Select service location"
          items={account?.serviceLocations || []}
          itemToString={(item?: ServiceLocationSelectItemFragment) =>
            item?.name ?? ''
          }
          selectedItem={account?.serviceLocations.find(
            (sl) => sl.id === serviceLocationId,
          )}
          onChange={(e) => {
            setValue('serviceLocationId', e.selectedItem?.id);
          }}
          disabled={!accountId}
        />
        <ComboBox
          id="driverId"
          data-1p-ignore
          titleText="Assigned driver"
          placeholder="Select driver"
          items={drivers}
          itemToString={(item?: UserSelectItemFragment) => item?.name ?? ''}
          selectedItem={drivers.find((d) => d.id === driverId)}
          onChange={(e) => {
            setValue('driverId', e.selectedItem?.id);
          }}
          readOnly={!drivers.length}
        />
      </Stack>
    </Form>
  );
};
