export const commodityTypes = [
  'ACSR',
  'Aluminum auto or truck wheels',
  'Aluminum extrusions',
  'Aluminum ISR copper radiators',
  'Auto radiators',
  'Bead wire',
  'Bushelling',
  'Clean auto cast',
  'Cut structural and plate scrap',
  'EC aluminum radiators',
  'Electric motors',
  'Film',
  'Hard brass',
  'HDPE',
  'Heavy breakable cast',
  'Irony aluminum',
  'LDPE',
  'Lead batteries',
  'Lead wheel weights',
  'Light copper',
  'Machine shop turnings',
  'Mixed aluminum',
  'Mixed hard/soft scrap lead',
  'Mixed low copper aluminum',
  'Mixed paper (MP)',
  'Nickel scrap',
  'No. 1 copper',
  'No. 1 heavy melting steel',
  'No. 2 copper',
  'No. 2 heavy melting steel',
  'Old corrugated containers (OCC)',
  'Old sheet aluminum',
  'Painted siding',
  'PET',
  'Punchings and plate scrap',
  'PVC',
  'Red brass',
  'Sheet aluminum',
  'Shredded scrap',
  'Sorted clean new (SCN)',
  'Stainless steel scrap',
  'UBC scrap',
  'Unsorted zinc die cast',
  'Wheel weights',
  'Yellow brass',
];
