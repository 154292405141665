import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UnAssignBinBarDocument = gql`
  mutation UnAssignBinBar($id: ID!) {
    updateContainerSiteBinBar(id: $id) {
      id
    }
  }
`;
export type UnAssignBinBarMutationFn = Apollo.MutationFunction<
  UnAssignBinBarMutation,
  UnAssignBinBarMutationVariables
>;

/**
 * __useUnAssignBinBarMutation__
 *
 * To run a mutation, you first call `useUnAssignBinBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAssignBinBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAssignBinBarMutation, { data, loading, error }] = useUnAssignBinBarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnAssignBinBarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnAssignBinBarMutation,
    UnAssignBinBarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnAssignBinBarMutation,
    UnAssignBinBarMutationVariables
  >(UnAssignBinBarDocument, options);
}
export type UnAssignBinBarMutationHookResult = ReturnType<
  typeof useUnAssignBinBarMutation
>;
export type UnAssignBinBarMutationResult =
  Apollo.MutationResult<UnAssignBinBarMutation>;
export type UnAssignBinBarMutationOptions = Apollo.BaseMutationOptions<
  UnAssignBinBarMutation,
  UnAssignBinBarMutationVariables
>;
export type UnAssignBinBarMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type UnAssignBinBarMutation = {
  __typename?: 'Mutation';
  updateContainerSiteBinBar: { __typename?: 'ContainerSite'; id: string };
};
