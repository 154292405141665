import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UpdateContainerSiteDocument = gql`
  mutation UpdateContainerSite($id: ID!, $input: UpdateContainerSiteInput!) {
    updateContainerSite(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateContainerSiteMutationFn = Apollo.MutationFunction<
  UpdateContainerSiteMutation,
  UpdateContainerSiteMutationVariables
>;

/**
 * __useUpdateContainerSiteMutation__
 *
 * To run a mutation, you first call `useUpdateContainerSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerSiteMutation, { data, loading, error }] = useUpdateContainerSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContainerSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContainerSiteMutation,
    UpdateContainerSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContainerSiteMutation,
    UpdateContainerSiteMutationVariables
  >(UpdateContainerSiteDocument, options);
}
export type UpdateContainerSiteMutationHookResult = ReturnType<
  typeof useUpdateContainerSiteMutation
>;
export type UpdateContainerSiteMutationResult =
  Apollo.MutationResult<UpdateContainerSiteMutation>;
export type UpdateContainerSiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateContainerSiteMutation,
  UpdateContainerSiteMutationVariables
>;
export type UpdateContainerSiteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateContainerSiteInput;
}>;

export type UpdateContainerSiteMutation = {
  __typename?: 'Mutation';
  updateContainerSite: { __typename?: 'ContainerSite'; id: string };
};
