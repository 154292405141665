import React, { useCallback } from 'react';

import { Modal, Toggle, Loading, Stack, ToggleSkeleton } from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import {
  useGetAlertSubscriptionsLazyQuery,
  useHasAlertSubscriptionsQuery,
  useUpdateAlertSubscriptionsMutation,
} from './_generated';
import { ContainerSiteTableRowFragment } from '../_generated';
import {
  AlertSubscriptionsForm,
  AlertSubscriptionsFormData,
} from '../AlertSubscriptionForm';

export type EditAlertSubscriptionsProps = {
  containerSite: ContainerSiteTableRowFragment;
};

export const EditAlertSubscriptions: React.FunctionComponent<
  EditAlertSubscriptionsProps
> = ({ containerSite }) => {
  const formMethods = useForm<AlertSubscriptionsFormData>({
    mode: 'all',
    defaultValues: {
      alertSubscriptions: [],
    },
  });

  const { data, loading } = useHasAlertSubscriptionsQuery({
    variables: { containerSiteId: containerSite.id },
  });

  const [getSubscriptions] = useGetAlertSubscriptionsLazyQuery({
    variables: { containerSiteId: containerSite.id },
    onCompleted: (data) => {
      formMethods.setValue('alertSubscriptions', data.user.alertSubscriptions);
    },
  });

  const [updateSubscriptions, { loading: updating }] =
    useUpdateAlertSubscriptionsMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => {
      if (loading) {
        return (
          <Stack orientation="horizontal" gap={6}>
            <ToggleSkeleton aria-label="Loading" />
          </Stack>
        );
      }

      return (
        <Toggle
          id={`alert-subscription-toggle-${containerSite.id}`}
          hideLabel
          onToggle={async () => {
            await getSubscriptions();
            setOpen(true);
          }}
          labelText={data?.user.hasAlertSubscriptions ? 'On' : 'Off'}
          toggled={data?.user.hasAlertSubscriptions}
        />
      );
    },
    [getSubscriptions, data, loading, containerSite.id],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <Modal
        open={open}
        preventCloseOnClickOutside
        modalHeading={`Edit alert subscriptions for ${containerSite.name}`}
        primaryButtonText="Save"
        onRequestClose={() => {
          setOpen(false);
        }}
        onRequestSubmit={formMethods.handleSubmit(
          async (data: AlertSubscriptionsFormData) => {
            await updateSubscriptions({
              variables: {
                containerSiteId: containerSite.id,
                input: data.alertSubscriptions.map((s) => ({
                  alertType: s.alertType,
                  sendEmail: s.sendEmail,
                  sendSms: s.sendSms,
                  sendSlack: false,
                })),
              },
            });

            setOpen(false);
          },
        )}
      >
        <>
          <Loading withOverlay active={updating} />
          <FormProvider {...formMethods}>
            <AlertSubscriptionsForm />
          </FormProvider>
        </>
      </Modal>
    ),
    [containerSite, formMethods, updateSubscriptions, updating],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`edit-alert-subscriptions-${containerSite.id}`}
    />
  );
};
