import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import {
  AccountSelectItemFragmentDoc,
  UserSelectItemFragmentDoc,
  ServiceLocationSelectItemWithAccountFragmentDoc,
  ServiceLocationSelectItemFragmentDoc,
  ContainerSiteSelectItemFragmentDoc,
} from '../../../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAllAccountsDocument = gql`
  query GetAllAccounts {
    accounts: searchAccounts(page: { page: 0, pageSize: 0 }) {
      ...AccountSelectItem
    }
  }
  ${AccountSelectItemFragmentDoc}
`;

/**
 * __useGetAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAccountsQuery,
    GetAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(
    GetAllAccountsDocument,
    options,
  );
}
export function useGetAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAccountsQuery,
    GetAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(
    GetAllAccountsDocument,
    options,
  );
}
export function useGetAllAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAllAccountsQuery,
        GetAllAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllAccountsQuery,
    GetAllAccountsQueryVariables
  >(GetAllAccountsDocument, options);
}
export type GetAllAccountsQueryHookResult = ReturnType<
  typeof useGetAllAccountsQuery
>;
export type GetAllAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountsLazyQuery
>;
export type GetAllAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllAccountsSuspenseQuery
>;
export type GetAllAccountsQueryResult = Apollo.QueryResult<
  GetAllAccountsQuery,
  GetAllAccountsQueryVariables
>;
export const GetServiceLocationsForAccountDocument = gql`
  query GetServiceLocationsForAccount($id: ID!) {
    account: accountById(id: $id) {
      id
      serviceLocations {
        id
        name
      }
    }
  }
`;

/**
 * __useGetServiceLocationsForAccountQuery__
 *
 * To run a query within a React component, call `useGetServiceLocationsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceLocationsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceLocationsForAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceLocationsForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceLocationsForAccountQuery,
    GetServiceLocationsForAccountQueryVariables
  > &
    (
      | {
          variables: GetServiceLocationsForAccountQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceLocationsForAccountQuery,
    GetServiceLocationsForAccountQueryVariables
  >(GetServiceLocationsForAccountDocument, options);
}
export function useGetServiceLocationsForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceLocationsForAccountQuery,
    GetServiceLocationsForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceLocationsForAccountQuery,
    GetServiceLocationsForAccountQueryVariables
  >(GetServiceLocationsForAccountDocument, options);
}
export function useGetServiceLocationsForAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetServiceLocationsForAccountQuery,
        GetServiceLocationsForAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceLocationsForAccountQuery,
    GetServiceLocationsForAccountQueryVariables
  >(GetServiceLocationsForAccountDocument, options);
}
export type GetServiceLocationsForAccountQueryHookResult = ReturnType<
  typeof useGetServiceLocationsForAccountQuery
>;
export type GetServiceLocationsForAccountLazyQueryHookResult = ReturnType<
  typeof useGetServiceLocationsForAccountLazyQuery
>;
export type GetServiceLocationsForAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceLocationsForAccountSuspenseQuery
>;
export type GetServiceLocationsForAccountQueryResult = Apollo.QueryResult<
  GetServiceLocationsForAccountQuery,
  GetServiceLocationsForAccountQueryVariables
>;
export const GetAllDriversDocument = gql`
  query GetAllDrivers {
    drivers: searchUsers(
      filter: [{ filterBy: ACCOUNT_ROLE, value: "DRIVER" }]
      page: { page: 0, pageSize: 0 }
    ) {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;

/**
 * __useGetAllDriversQuery__
 *
 * To run a query within a React component, call `useGetAllDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDriversQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllDriversQuery,
    GetAllDriversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(
    GetAllDriversDocument,
    options,
  );
}
export function useGetAllDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDriversQuery,
    GetAllDriversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(
    GetAllDriversDocument,
    options,
  );
}
export function useGetAllDriversSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAllDriversQuery,
        GetAllDriversQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllDriversQuery,
    GetAllDriversQueryVariables
  >(GetAllDriversDocument, options);
}
export type GetAllDriversQueryHookResult = ReturnType<
  typeof useGetAllDriversQuery
>;
export type GetAllDriversLazyQueryHookResult = ReturnType<
  typeof useGetAllDriversLazyQuery
>;
export type GetAllDriversSuspenseQueryHookResult = ReturnType<
  typeof useGetAllDriversSuspenseQuery
>;
export type GetAllDriversQueryResult = Apollo.QueryResult<
  GetAllDriversQuery,
  GetAllDriversQueryVariables
>;
export type GetAllAccountsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAllAccountsQuery = {
  __typename?: 'Query';
  accounts: Array<{ __typename?: 'Account'; id: string; name: string }>;
};

export type GetServiceLocationsForAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetServiceLocationsForAccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    serviceLocations: Array<{
      __typename?: 'ServiceLocation';
      id: string;
      name: string;
    }>;
  };
};

export type GetAllDriversQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllDriversQuery = {
  __typename?: 'Query';
  drivers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
};
