import React from 'react';

import styles from './NoContainersHint.module.scss';

export const NoContainersHint: React.FunctionComponent = () => {
  return (
    <div className={styles['hint-container']}>
      <h5>No container sites</h5>
      <p>
        Remove all filters or click <em>New container site</em> above to start
        monitoring a new site.
      </p>
      <p>
        See{' '}
        <a
          href="https://help.scalable-systems.io/docs/client-application/create-a-container-site/"
          target="_blank"
        >
          help.scalable-systems.io
        </a>{' '}
        for more help.
      </p>
    </div>
  );
};
