import { AlertType } from '#generated-types';

export const alerts = {
  [AlertType.ContainerSite_80PercentFull]: {
    name: 'Exceeds 80%',
    description:
      'Receive a notification when the container is more than 80% full',
  },
  [AlertType.ContainerSite_100PercentFull]: {
    name: 'Exceeds 100%',
    description:
      'Receive a notification when the container is more than 100% full',
  },
  [AlertType.BinbarLowBattery]: {
    name: 'Low battery',
    description: 'Receive a notification when the BinBar battery is below 5%',
  },
};
