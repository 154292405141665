import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UpdateAlertSubscriptionsDocument = gql`
  mutation UpdateAlertSubscriptions(
    $containerSiteId: ID!
    $input: [AlertSubscriptionInput!]!
  ) {
    updateCurrentUserAlertSubscriptions(
      containerSiteId: $containerSiteId
      input: $input
    ) {
      id
      hasAlertSubscriptions(containerSiteId: $containerSiteId)
      alertSubscriptions(containerSiteId: $containerSiteId) {
        id
        alertType
        sendEmail
        sendSms
      }
    }
  }
`;
export type UpdateAlertSubscriptionsMutationFn = Apollo.MutationFunction<
  UpdateAlertSubscriptionsMutation,
  UpdateAlertSubscriptionsMutationVariables
>;

/**
 * __useUpdateAlertSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateAlertSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertSubscriptionsMutation, { data, loading, error }] = useUpdateAlertSubscriptionsMutation({
 *   variables: {
 *      containerSiteId: // value for 'containerSiteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlertSubscriptionsMutation,
    UpdateAlertSubscriptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAlertSubscriptionsMutation,
    UpdateAlertSubscriptionsMutationVariables
  >(UpdateAlertSubscriptionsDocument, options);
}
export type UpdateAlertSubscriptionsMutationHookResult = ReturnType<
  typeof useUpdateAlertSubscriptionsMutation
>;
export type UpdateAlertSubscriptionsMutationResult =
  Apollo.MutationResult<UpdateAlertSubscriptionsMutation>;
export type UpdateAlertSubscriptionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAlertSubscriptionsMutation,
    UpdateAlertSubscriptionsMutationVariables
  >;
export const HasAlertSubscriptionsDocument = gql`
  query HasAlertSubscriptions($containerSiteId: ID!) {
    user: currentUser {
      id
      hasAlertSubscriptions(containerSiteId: $containerSiteId)
    }
  }
`;

/**
 * __useHasAlertSubscriptionsQuery__
 *
 * To run a query within a React component, call `useHasAlertSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAlertSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAlertSubscriptionsQuery({
 *   variables: {
 *      containerSiteId: // value for 'containerSiteId'
 *   },
 * });
 */
export function useHasAlertSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasAlertSubscriptionsQuery,
    HasAlertSubscriptionsQueryVariables
  > &
    (
      | { variables: HasAlertSubscriptionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasAlertSubscriptionsQuery,
    HasAlertSubscriptionsQueryVariables
  >(HasAlertSubscriptionsDocument, options);
}
export function useHasAlertSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasAlertSubscriptionsQuery,
    HasAlertSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasAlertSubscriptionsQuery,
    HasAlertSubscriptionsQueryVariables
  >(HasAlertSubscriptionsDocument, options);
}
export function useHasAlertSubscriptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HasAlertSubscriptionsQuery,
        HasAlertSubscriptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HasAlertSubscriptionsQuery,
    HasAlertSubscriptionsQueryVariables
  >(HasAlertSubscriptionsDocument, options);
}
export type HasAlertSubscriptionsQueryHookResult = ReturnType<
  typeof useHasAlertSubscriptionsQuery
>;
export type HasAlertSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useHasAlertSubscriptionsLazyQuery
>;
export type HasAlertSubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useHasAlertSubscriptionsSuspenseQuery
>;
export type HasAlertSubscriptionsQueryResult = Apollo.QueryResult<
  HasAlertSubscriptionsQuery,
  HasAlertSubscriptionsQueryVariables
>;
export const GetAlertSubscriptionsDocument = gql`
  query GetAlertSubscriptions($containerSiteId: ID!) {
    user: currentUser {
      id
      alertSubscriptions(containerSiteId: $containerSiteId) {
        id
        alertType
        sendEmail
        sendSms
      }
    }
  }
`;

/**
 * __useGetAlertSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetAlertSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertSubscriptionsQuery({
 *   variables: {
 *      containerSiteId: // value for 'containerSiteId'
 *   },
 * });
 */
export function useGetAlertSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAlertSubscriptionsQuery,
    GetAlertSubscriptionsQueryVariables
  > &
    (
      | { variables: GetAlertSubscriptionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAlertSubscriptionsQuery,
    GetAlertSubscriptionsQueryVariables
  >(GetAlertSubscriptionsDocument, options);
}
export function useGetAlertSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertSubscriptionsQuery,
    GetAlertSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAlertSubscriptionsQuery,
    GetAlertSubscriptionsQueryVariables
  >(GetAlertSubscriptionsDocument, options);
}
export function useGetAlertSubscriptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAlertSubscriptionsQuery,
        GetAlertSubscriptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAlertSubscriptionsQuery,
    GetAlertSubscriptionsQueryVariables
  >(GetAlertSubscriptionsDocument, options);
}
export type GetAlertSubscriptionsQueryHookResult = ReturnType<
  typeof useGetAlertSubscriptionsQuery
>;
export type GetAlertSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetAlertSubscriptionsLazyQuery
>;
export type GetAlertSubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetAlertSubscriptionsSuspenseQuery
>;
export type GetAlertSubscriptionsQueryResult = Apollo.QueryResult<
  GetAlertSubscriptionsQuery,
  GetAlertSubscriptionsQueryVariables
>;
export type UpdateAlertSubscriptionsMutationVariables = Types.Exact<{
  containerSiteId: Types.Scalars['ID']['input'];
  input: Array<Types.AlertSubscriptionInput> | Types.AlertSubscriptionInput;
}>;

export type UpdateAlertSubscriptionsMutation = {
  __typename?: 'Mutation';
  updateCurrentUserAlertSubscriptions?: {
    __typename?: 'User';
    id: string;
    hasAlertSubscriptions: boolean;
    alertSubscriptions: Array<{
      __typename?: 'AlertSubscription';
      id: string;
      alertType: Types.AlertType;
      sendEmail: boolean;
      sendSms: boolean;
    }>;
  } | null;
};

export type HasAlertSubscriptionsQueryVariables = Types.Exact<{
  containerSiteId: Types.Scalars['ID']['input'];
}>;

export type HasAlertSubscriptionsQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; hasAlertSubscriptions: boolean };
};

export type GetAlertSubscriptionsQueryVariables = Types.Exact<{
  containerSiteId: Types.Scalars['ID']['input'];
}>;

export type GetAlertSubscriptionsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    alertSubscriptions: Array<{
      __typename?: 'AlertSubscription';
      id: string;
      alertType: Types.AlertType;
      sendEmail: boolean;
      sendSms: boolean;
    }>;
  };
};
