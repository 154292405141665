import { useCallback } from 'react';

import { Filter, FilterRemove } from '@carbon/icons-react';
import { IconButton, Modal } from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ContainerSiteFilter,
  ContainerSiteFilterInput,
} from '#generated-types';
import {
  ContainerSitesFilterForm,
  ContainerSitesFilterFormData,
} from '../ContainerSitesFilterForm';

type ContainerSitesFilterProps = {
  initialFilter: ContainerSiteFilterInput[];
  onFilter: (filter: ContainerSiteFilterInput[]) => void;
};

export const ContainerSitesFilter: React.FunctionComponent<
  ContainerSitesFilterProps
> = ({ initialFilter, onFilter }) => {
  const formMethods = useForm<ContainerSitesFilterFormData>({
    mode: 'all',
    defaultValues: {
      accountId:
        initialFilter?.find((f) => f.filterBy === ContainerSiteFilter.AccountId)
          ?.value || undefined,
      serviceLocationId:
        initialFilter?.find(
          (f) => f.filterBy === ContainerSiteFilter.ServiceLocationId,
        )?.value || undefined,
      hasBinBar:
        initialFilter?.find((f) => f.filterBy === ContainerSiteFilter.HasBinbar)
          ?.value === 'true' || undefined,
      driverId:
        initialFilter?.find(
          (f) => f.filterBy === ContainerSiteFilter.AssignedDriverId,
        )?.value || undefined,
    },
  });

  const clearFilters = useCallback(() => {
    formMethods.reset({
      accountId: undefined,
      serviceLocationId: undefined,
      hasBinBar: undefined,
      driverId: undefined,
    });
  }, [formMethods]);

  const renderLauncher = useCallback(
    ({ setOpen }) => {
      const values = formMethods.getValues();
      const hasFilter = Object.values(values).some((v) => v !== undefined);

      return (
        <>
          {hasFilter && (
            <IconButton
              label="Clear all filters"
              align="top-right"
              kind="ghost"
              onClick={() => {
                clearFilters();
                onFilter([]);
              }}
            >
              <FilterRemove />
            </IconButton>
          )}
          <IconButton
            label="Filter"
            align="top-right"
            kind="ghost"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Filter className={hasFilter ? 'ww--fill-orange' : undefined} />
          </IconButton>
        </>
      );
    },
    [formMethods, onFilter, clearFilters],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <Modal
        open={open}
        preventCloseOnClickOutside
        modalHeading="Filter container sites"
        primaryButtonText="Filter"
        secondaryButtonText="Clear all filters"
        selectorPrimaryFocus="#accountId"
        onRequestClose={() => {
          setOpen(false);
        }}
        onRequestSubmit={formMethods.handleSubmit(
          (data: ContainerSitesFilterFormData) => {
            const filter: ContainerSiteFilterInput[] = [];

            if (data.accountId) {
              filter.push({
                filterBy: ContainerSiteFilter.AccountId,
                value: data.accountId,
              });
            }

            if (data.serviceLocationId) {
              filter.push({
                filterBy: ContainerSiteFilter.ServiceLocationId,
                value: data.serviceLocationId,
              });
            }

            if (data.hasBinBar) {
              filter.push({
                filterBy: ContainerSiteFilter.HasBinbar,
                value: data.hasBinBar.toString(),
              });
            }

            if (data.driverId) {
              filter.push({
                filterBy: ContainerSiteFilter.AssignedDriverId,
                value: data.driverId,
              });
            }

            onFilter(filter);
            setOpen(false);
          },
        )}
        onSecondarySubmit={() => {
          clearFilters();
          onFilter([]);
          setOpen(false);
        }}
      >
        <FormProvider {...formMethods}>
          <ContainerSitesFilterForm />
        </FormProvider>
      </Modal>
    ),
    [formMethods, onFilter, clearFilters],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`filter-container-sites-modal`}
    />
  );
};
