import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import {
  UserSelectItemFragmentDoc,
  ServiceLocationSelectItemWithAccountFragmentDoc,
} from '../../../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetDriversForAccountDocument = gql`
  query GetDriversForAccount($accountId: String!) {
    drivers: searchUsers(
      filter: [
        { filterBy: ACCOUNT_ID, value: $accountId }
        { filterBy: ACCOUNT_ROLE, value: "DRIVER" }
      ]
      page: { page: 0, pageSize: 0 }
    ) {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;

/**
 * __useGetDriversForAccountQuery__
 *
 * To run a query within a React component, call `useGetDriversForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetDriversForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  > &
    (
      | { variables: GetDriversForAccountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export function useGetDriversForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export function useGetDriversForAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDriversForAccountQuery,
        GetDriversForAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDriversForAccountQuery,
    GetDriversForAccountQueryVariables
  >(GetDriversForAccountDocument, options);
}
export type GetDriversForAccountQueryHookResult = ReturnType<
  typeof useGetDriversForAccountQuery
>;
export type GetDriversForAccountLazyQueryHookResult = ReturnType<
  typeof useGetDriversForAccountLazyQuery
>;
export type GetDriversForAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetDriversForAccountSuspenseQuery
>;
export type GetDriversForAccountQueryResult = Apollo.QueryResult<
  GetDriversForAccountQuery,
  GetDriversForAccountQueryVariables
>;
export const GetAllServiceLocationsDocument = gql`
  query GetAllServiceLocations {
    serviceLocations: searchServiceLocations(page: { page: 0, pageSize: 0 }) {
      ...ServiceLocationSelectItemWithAccount
    }
  }
  ${ServiceLocationSelectItemWithAccountFragmentDoc}
`;

/**
 * __useGetAllServiceLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllServiceLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllServiceLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllServiceLocationsQuery,
    GetAllServiceLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllServiceLocationsQuery,
    GetAllServiceLocationsQueryVariables
  >(GetAllServiceLocationsDocument, options);
}
export function useGetAllServiceLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllServiceLocationsQuery,
    GetAllServiceLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllServiceLocationsQuery,
    GetAllServiceLocationsQueryVariables
  >(GetAllServiceLocationsDocument, options);
}
export function useGetAllServiceLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAllServiceLocationsQuery,
        GetAllServiceLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllServiceLocationsQuery,
    GetAllServiceLocationsQueryVariables
  >(GetAllServiceLocationsDocument, options);
}
export type GetAllServiceLocationsQueryHookResult = ReturnType<
  typeof useGetAllServiceLocationsQuery
>;
export type GetAllServiceLocationsLazyQueryHookResult = ReturnType<
  typeof useGetAllServiceLocationsLazyQuery
>;
export type GetAllServiceLocationsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllServiceLocationsSuspenseQuery
>;
export type GetAllServiceLocationsQueryResult = Apollo.QueryResult<
  GetAllServiceLocationsQuery,
  GetAllServiceLocationsQueryVariables
>;
export type GetDriversForAccountQueryVariables = Types.Exact<{
  accountId: Types.Scalars['String']['input'];
}>;

export type GetDriversForAccountQuery = {
  __typename?: 'Query';
  drivers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
};

export type GetAllServiceLocationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAllServiceLocationsQuery = {
  __typename?: 'Query';
  serviceLocations: Array<{
    __typename?: 'ServiceLocation';
    id: string;
    name: string;
    account: { __typename?: 'Account'; id: string };
  }>;
};
