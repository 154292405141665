import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import { BinBarSelectItemFragmentDoc } from '../../../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AssignBinBarDocument = gql`
  mutation AssignBinBar($id: ID!, $binBarId: ID!) {
    updateContainerSiteBinBar(id: $id, binBarId: $binBarId) {
      id
    }
  }
`;
export type AssignBinBarMutationFn = Apollo.MutationFunction<
  AssignBinBarMutation,
  AssignBinBarMutationVariables
>;

/**
 * __useAssignBinBarMutation__
 *
 * To run a mutation, you first call `useAssignBinBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBinBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBinBarMutation, { data, loading, error }] = useAssignBinBarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      binBarId: // value for 'binBarId'
 *   },
 * });
 */
export function useAssignBinBarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignBinBarMutation,
    AssignBinBarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignBinBarMutation,
    AssignBinBarMutationVariables
  >(AssignBinBarDocument, options);
}
export type AssignBinBarMutationHookResult = ReturnType<
  typeof useAssignBinBarMutation
>;
export type AssignBinBarMutationResult =
  Apollo.MutationResult<AssignBinBarMutation>;
export type AssignBinBarMutationOptions = Apollo.BaseMutationOptions<
  AssignBinBarMutation,
  AssignBinBarMutationVariables
>;
export const GetAllBinBarsDocument = gql`
  query GetAllBinBars {
    binBars: searchBinBars(page: { page: 0, pageSize: 0 }) {
      ...BinBarSelectItem
      account {
        id
      }
      containerSite {
        id
      }
    }
  }
  ${BinBarSelectItemFragmentDoc}
`;

/**
 * __useGetAllBinBarsQuery__
 *
 * To run a query within a React component, call `useGetAllBinBarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBinBarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBinBarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBinBarsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBinBarsQuery,
    GetAllBinBarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllBinBarsQuery, GetAllBinBarsQueryVariables>(
    GetAllBinBarsDocument,
    options,
  );
}
export function useGetAllBinBarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBinBarsQuery,
    GetAllBinBarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllBinBarsQuery, GetAllBinBarsQueryVariables>(
    GetAllBinBarsDocument,
    options,
  );
}
export function useGetAllBinBarsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAllBinBarsQuery,
        GetAllBinBarsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllBinBarsQuery,
    GetAllBinBarsQueryVariables
  >(GetAllBinBarsDocument, options);
}
export type GetAllBinBarsQueryHookResult = ReturnType<
  typeof useGetAllBinBarsQuery
>;
export type GetAllBinBarsLazyQueryHookResult = ReturnType<
  typeof useGetAllBinBarsLazyQuery
>;
export type GetAllBinBarsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllBinBarsSuspenseQuery
>;
export type GetAllBinBarsQueryResult = Apollo.QueryResult<
  GetAllBinBarsQuery,
  GetAllBinBarsQueryVariables
>;
export type AssignBinBarMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  binBarId: Types.Scalars['ID']['input'];
}>;

export type AssignBinBarMutation = {
  __typename?: 'Mutation';
  updateContainerSiteBinBar: { __typename?: 'ContainerSite'; id: string };
};

export type GetAllBinBarsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllBinBarsQuery = {
  __typename?: 'Query';
  binBars: Array<{
    __typename?: 'BinBar';
    id: string;
    name: string;
    account?: { __typename?: 'Account'; id: string } | null;
    containerSite?: { __typename?: 'ContainerSite'; id: string } | null;
  }>;
};
